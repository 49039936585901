.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.sr-only {
  display: none;
}

.home_image {
  background: linear-gradient(to right, rgba(255,255,255,0) 20%, rgba(255,255,255,1))
}

.demo-wrap {
  position: relative;
}

.demo-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.demo-content {
  position: relative;
}

.backImage {
  background-size:cover !important;
  background-repeat: no-repeat !important;
}

.imageFade {
  /* background-image: linear-gradient(to bottom, transparent 0%, #000000 70%), url("https://parallelmc.org/wp-content/uploads/2022/04/homepage-background.jpg"); */
  background-position: center !important;
  background-repeat: no-repeat!important; 
  background-size: contain !important;
  background-position: top !important;
  /*background-attachment: fixed;*/
  backdrop-filter: blur(3px) !important;
  background-color: #495057 !important;
  box-shadow: 0 0 8px 8px white inset !important;
}

.textModalGame {
  margin-left: 10px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ioKlTy {
  margin: 5px;
}